.work-card-content .card-color-text,
.card-link-sec a {
    color: var(--yellowClr);
    font-family: 'Maven Pro' , sans-serif;
}

.work-card-content {
    color: var(--whiteClr);
    width: 80%;
}

.card-img img {
    width: 100%;
    margin-bottom: 25px;
}

.work-card-sec .row {
    justify-content: space-between;
    margin: 0px;
    flex-wrap: nowrap;
    width: 85%;
    margin: auto;
    align-items: flex-end;
}

.our-work-main h1 {
    color: var(--darkorangeClr);
    text-align: center;
    margin-bottom: 45px;
    font-family: 'ArgueDemo' , sans-serif;
}

.our-work-main {
    padding: 4% 0%;
}

.work-card-sec {
    margin-bottom: 55px;
}

.work-card-content p {
    margin-bottom: 0px;
    font-family: 'Maven Pro' , sans-serif;

}

.more-project-btn button {
    border: 0px;
    background: linear-gradient(to right, var(--orangeClr), var(--redClr), var(--redClr));
    color: var(--white);
    padding: 10px 45px;
    border-radius: 12px;
    font-weight: 700;
    font-family: 'Maven Pro' , sans-serif;
}

.more-project-btn {
    text-align: center;
}