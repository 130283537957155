.speciality-content h1{
    font-size:3.5rem;
}
.speciality-content h1,
.speciality-content h5 {
    color: var(--whiteClr);
}

.speciality-content span {
    color: var(--darkorangeClr);
}

.speciality-image img {
    width: 100%;
}

.speciality-main .row {
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
}

.speciality-content h5 {
    line-height: 30px;
    margin-top: 22px;
    margin-bottom: 20px;
    font-family: 'Maven Pro';
}

.speciality-main {
    padding: 4% 0%;
}

.speciality-content h1{
    font-family: 'ArgueDemo', sans-serif;
}