:root {
  --whiteClr: #fff;
  --blackClr: #000;
  --blueClr: #181833;
  --darkblueClr: #000011;
  --yellowClr: #FFDF00;
  --orangeClr: #FF9000;
  --redClr: #FF0000;
  --darkorangeClr: #D85A13;
  --lightyellowClr: #E1FF06;
  --lightgreenClr: #69FF08;
  --skyBlue: #08E2FF;

}

html,
body {
  width: 100%;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
}

@import url('./Assets/Css/Header.css');
@import url('./Assets/Css/Banner.css');
@import url('./Assets/Css/Card.css');
@import url('./Assets/Css/Footer.css');
@import url('./Assets/Css/Contact.css');
@import url('./Assets/Css/Home.css');
@import url('./Assets/Css/Count.css');
@import url('./Assets/Css/Speciality.css');
@import url('./Assets/Css/Tree.css');
@import url('./Assets/Css/Statergic.css');
@import url('./Assets/Css/WorkCard.css');
@import url('./Assets/Css/About.css');


@font-face {
  font-family: 'ArgueDemo';
  src: url('./Assets/Fonts/FontsFree-Net-Arguedemo-w1PrZ.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('./Assets/Fonts/Avenir\ Heavy.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Maven Pro';
  src: url('./Assets/Fonts/MavenPro-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

p,
h4,
h5 {
  font-family: 'Mavin Pro', 'sans-serif';
}

h1,
h2,
h3 {
  font-family: 'Avenir Heavy', 'sans-serif';
}


.App .common-space {
  padding: 0px 6%;
}

@media screen and (min-width: 1300px) {
  .bottom-center {
    bottom: 60px;
  }
}

@media screen and (min-width: 1800px) {
  .bottom-center {
    bottom: 154px;
  }
}

@media screen and (min-width: 2000px) {
  .bottom-center {
    bottom: 170px;
  }
}

@media screen and (max-width: 1200px) {
  .banner-sec .banner-content h1 {
    font-size: 40px;
  }

  .contact-content h1 {
    font-size: 40px;
  }

  .about-text {
    gap: 25px;
  }

  .count-sec h4 {
    font-size: 18px;
  }

  .count-sec h2 {
    font-size: 25px;
  }

  .bottom-center {
    height: 165px;
  }

  .tree-content {
    width: 35.5%;
  }

  .common-overlay-sec {
    padding: 12px;
  }

  .team-data-sec {
    margin-bottom: 5px;
  }

  .hide-linked-img {
    bottom: 15px;
  }

  .common-overlay-sec {
    border-radius: 25px;
  }

  .team-card-sec-bg {
    border-radius: 33px;
  }
}

@media screen and (max-width: 1000px) {
  .card-content h3 {
    font-size: 20px;
  }

  .card-content h5 {
    font-size: 16px;
  }

  .card-sec .card-content {
    top: 35%;
    padding: 0px 15px;
  }

  .count-main .row {
    justify-content: center;
    gap: 15px;
  }

  .speciality-content h5 {
    margin-bottom: 30px;
  }

  .tree-content {
    width: 42%;
  }

  .bottom-center {
    height: auto;
  }

  .tree-content .tree-head {
    font-size: 14px;
  }

  .tree-content .tree-text {
    font-size: 12px;
  }

  .tree-content {
    padding: 1% 2%;
  }

  .work-card-sec .row {
    flex-wrap: wrap;
    width: 100%;
  }

  .work-card-content {
    width: 100%;
    margin-bottom: 10px;
  }

  .vision-text {
    margin-top: 20px;
  }

  .mission-image img {
    padding: 0% 6%;
    margin-top: 25px;
  }
}

@media screen and (max-width: 700px) {
  .banner-sec .banner-content h1 {
    font-size: 30px;
  }

  .banner-sec .banner-content .row button {
    color: var(--white);
    padding: 10px 25px;
    font-size: 12px;
  }

  .about-head {
    font-size: 30px;
  }

  .about-text h1,
  .vision-main h1,
  .mission-head-text,
  .leader-text h1,
  .team-main h1 {
    font-size: 30px;
  }

  .about-text {
    gap: 12px;
  }

  .card-sec .card-content {
    top: 10px;
  }

  .card-content h5 {
    font-size: 14px;
  }

  .card-sec .card-content {
    padding: 0px 8px;
  }

  .speciality-content h1 {
    font-size: 30px;
  }

  .contact-content h1 {
    font-size: 30px;
  }

  .tree-sec-main .tree-content {
    position: inherit;
    transform: translateY(0%);
    width: 100%;
    left: 0px;
    right: 0px;
    margin-bottom: 13px;
    padding: 4% 6%;
  }

  .tree-content .tree-text {
    font-size: 16px;
  }

  .tree-content .tree-head {
    font-size: 20px;
  }

  .tree-sec img {
    display: none;
  }

  .tree-sec-main {
    padding: 10% 0%;
  }

  .card-img img {
    margin-bottom: 15px;
  }

  .work-card-sec {
    margin-bottom: 35px;
  }

  .affiliation-sec .head-orange {
    font-size: 30px;
  }

  .affiliation-sec h4,
  .affiliation-sec h5 {
    font-size: 20px;
  }

  .strategic-client-sec {
    width: 100%;
  }

}

@media screen and (max-width: 575px) {
  .card-main {
    transform: translateY(0%);
    padding-top: 10%;
  }

  .card-sec .card-content {
    top: 50%;
    transform: translateY(-50%);
  }

  .card-content h3 {
    font-size: 25px;
  }

  .card-content h5 {
    font-size: 20px;
  }

  .card-sec {
    margin-bottom: 35px;
  }

  .footer-sec .row-reverse-sec {
    margin-left: 0px;
  }

  .footer-sec .row {
    justify-content: center;
  }

  .footer-logo {
    text-align: center;
  }

  .contact-form-sec label {
    font-size: 16px;
  }

  .team-main h5 {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .banner-sec .banner-content {
    width: 100%;
  }

  .banner-sec .banner-content .row {
    margin-top: 10px;
  }

  .banner-sec .banner-content h1 {
    font-size: 16px;
  }

  .header-sec .fixed-top {
    top: 0px;
    padding-top: 2px;
  }

  .header-sec .logo-img {
    width: 45%;
  }

  .App button {
    font-size: 12px;
  }

  .team-card-sec-bg {
    border-radius: 30px;
  }

  .common-overlay-sec {
    border-radius: 21px;
  }
}

@media screen and (max-width: 405px) {

  .about-head,
  .about-text h1,
  .speciality-content h1,
  .contact-content h1,
  .affiliation-sec .head-orange,
  .vision-main h1,
  .mission-head-text,
  .leader-text h1,
  .team-main h1 {
    font-size: 25px;
  }

  .about-text h5,
  .speciality-content h5,
  .contact-content h5,
  .affiliation-sec h4,
  .affiliation-sec h5,
  .affiliation-sec h5,
  .vision-main h5,
  .mission-content .accordion-header button,
  .accord-head,
  .leader-text h5,
  .team-main h5 {
    font-size: 16px;
  }

  .speciality-content h5 {
    line-height: 24px;
    margin-bottom: 20px;
  }

  .speciality-main .row {
    margin-bottom: 28px;
  }

  .contact-content h5 {
    padding: 5% 0% 5% 0%;
  }

  .footer-links {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    margin: 20px 0px;
  }

  .footer-links .row,
  .footer-links .row a {
    display: block;
  }

  .banner-sec .banner-content {
    top: 65%;
  }

  .banner-sec .banner-content .row button {
    padding: 8px 15px;
  }

  .tree-content .tree-head {
    font-size: 18px;
  }

  .icon-img {
    float: right;
    margin-right: 0px;
    margin-top: 5px;
    width: 16px;
  }
}