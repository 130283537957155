.contact-main {
    background-color: var(--blueClr);
    padding: 5% 0px;
}

.contact-address h1,
.contact-address h5,
.contact-address a {
    color: var(--whiteClr);
}

.contact-address a:hover {
    color: var(--whiteClr);
    text-decoration: none;
    font-family: 'Maven Pro',sans-serif;
}

.contact-links img {
    width: 15px;
    margin-top: 5px;
}

.contact-links .row {
    gap: 10px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
    align-items: self-start;
}

.contact-content h1 {
    text-transform: uppercase;
    font-size: 52px;
}

.contact-content h5 {
    padding: 5% 0% 10% 0%;
    font-family: 'Maven Pro',sans-serif;

}

.contact-form-sec input,
.contact-form-sec textarea {
    background-color: transparent;
    font-size: 18px;
    color: var(--whiteClr);
    resize: none;
}

.contact-form-sec label {
    color: var(--whiteClr);
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 18px;
}

.contact-form-sec input {
    height: calc(1.5em + 2.5rem + 2px);
    margin-bottom: 35px;
}

.contact-form-sec .form-control:focus {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(to right, orange, red) 2;
    border-color: transparent;
    box-shadow: none;
    color: #fff;
}

.form-button {
    text-align: right;
}

.form-button button {
    border: 0px;
    text-transform: uppercase;
    border: 0px;
    background-image: linear-gradient(to right, var(--orangeClr), var(--redClr));
    color: var(--white);
    padding: 10px 45px;
    border-radius: 45px;
    font-weight: 700;
}
.contact-styling{
    color: #fff;
    font-family: 'Maven Pro',sans-serif;
}