.ethos-main {
    padding: 5% 0%;
}

.vision-img img {
    width: 90%;
}

.vision-main h1,
.vision-main h5 {
    color: var(--whiteClr);
}

.vision-clr-change {
    color: var(--darkorangeClr);
    font-family: 'ArgueDemo', sans-serif;

}

.vision-main h1 {
    margin-bottom: 25px;
    font-family: 'ArgueDemo', sans-serif;

}

.vision-main h5 {
    margin-bottom: 20px;
    font-family: 'Maven Pro', sans-serif;
}

.mission-image img {
    width: 100%;
    padding-right: 6%;
}

.mission-main {
    margin-top: 6%;
}

.accord-head {
    color: var(--yellowClr);
    margin-bottom: 0px;
    font-family: 'Maven Pro', sans-serif;
}

.accord-white {
    color: var(--whiteClr);
    margin-bottom: 0px;
    font-family: 'Maven Pro', sans-serif;
}

.mission-content .accord-body {
    color: var(--whiteClr);
    font-family: 'Maven Pro', sans-serif;
}

.mission-content .accordion-header button {
    border: 0px;
    background: 0px;
    font-size: 20px;
    padding: 0px;
    width: 100%;
    text-align: left;

}

.mission-content .accordion-header {
    margin-bottom: 0px;
}

.bg-accord {
    background-color: var(--blueClr);
}

.accord-space {
    padding: 2% 0%;
    border-bottom: 1.5px solid var(--whiteClr);
}

.mission-head-text {
    color: var(--darkorangeClr);
    margin-bottom: 30px;
    font-family: 'ArgueDemo', sans-serif;

}

.icon-img {
    width: 25px;
    float: right;
    margin-right: 20px;
}

.leader-orange {
    color: var(--darkorangeClr);
}

.leader-text h5,
.leader-text h1 {
    color: var(--whiteClr);

}

.leader-text h1 {
    font-family: 'ArgueDemo', sans-serif;
}

.leader-text h5 {
    font-family: 'Maven Pro', sans-serif;

}

.leadership-main {
    padding: 6% 0%;
}

.leader-img img {
    width: 50%;
}

.leader-img {
    text-align: center;
}

.leadership-main .row {
    align-items: center;
}

.team-image {
    width: 100%;
}

.team-member-content .team-content-justify {
    margin: 0px;
    justify-content: flex-start;
    padding: 0px 15px;
    align-items: center;
}

.team-member-content {
    position: absolute;
    bottom: 15px;
    width: 90%;
    margin: auto;
    left: 0px;
    right: 0px;
    background-color: var(--blackClr);
    padding: 5px 0px;
    border-radius: 10px;
}

.content-left-side p {
    font-family: 'Maven Pro', sans-serif;
    margin-bottom: 0px;
    color: var(--whiteClr);
    font-size: 14px;
    line-height: 15px;
}

.member-name {
    font-weight: 700;
}

.content-right-side img {
    width: 22px;
    cursor: pointer;
}

.content-right-side img:first-child {
    margin-right: 10px;
}

.team-card-sec {
    position: relative;
}

.team-main {
    padding-top: 6%;
}

.team-head-content {
    color: var(--darkorangeClr);
}

.team-main h1,
.team-main h5 {
    color: var(--whiteClr);
    text-align: center;
}

.team-main h1 {
    margin-bottom: 20px;
    font-family: 'ArgueDemo', sans-serif;
}

.team-main h5 {
    font-family: 'Maven Pro',sans-serif;
    width: 70%;
    margin: auto;
}

.team-main-top-content {
    margin-bottom: 5%;
}

.team-data-sec {
    color: var(--darkorangeClr);
    margin-bottom: 20px;
}

.team-data-text {
    color: var(--whiteClr);
}

.hidden-content {
    z-index: 10;
}

.team-overlay {
    background-color: var(--blueClr);
    opacity: .7;
    z-index: 9;
}

.common-overlay-sec {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 25px;
    border-radius: 35px;
}

.hide-linked-img {
    text-align: right;
    right: 20px;
    bottom: 30px;
    position: absolute;
}

.team-data-sec p {
    margin-bottom: 0px;
}

.team-data-sec p {
    font-size: 15px;
}

.team-data-sec p:last-child {
    font-weight: 700;
}

.team-data-text {
    font-size: 13px;
}

.team-card-sec-bg {
    border: double 10px transparent;
    border-radius: 45px;
    /* display: inline-block; */
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, var(--yellowClr), var(--redClr));
    background-origin: border-box;
    background-clip: content-box, border-box;
}