.count-sec {
    color: var(--whiteClr);
    text-align: center;
}

.count-main .row {
    justify-content: space-between;
    position: relative;
    z-index: 10;
    margin: 0px;
}

.count-main {
    background-image: url('../Images/count-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: 100% 100%, cover;
    padding: 5% 0%;
    position: relative;
}

.count-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: var(--darkblueClr);
    opacity: .7;
    z-index: 9;
}

.orange-text {
    color: var(--darkorangeClr);
    font-family: 'Maven Pro' , sans-serif;
}

.light-green-text {
    color: var(--lightyellowClr);
    font-family: 'Maven Pro' , sans-serif;

}

.green-text {
    color: var(--lightgreenClr);
    font-family: 'Maven Pro' , sans-serif;

}

.blue-text {
    color: var(--skyBlue);
    font-family: 'Maven Pro' , sans-serif;

}